<script>
import Vue from "vue";
import ScanModeDialog from "@/components/inventory/ScanModeDialog";
import InvalidScanDialog from "@/components/inventory/InvalidScanDialog";
// TODO - move to gql directory and name it inventoryItemByDrsSki
import InventoryQuery from "../InventoryQuery.gql";
import { mountsBarcodeHandlerWhenActive } from "@/mixins/barcode";
import { values, keys } from "lodash";

export default {
  name: "InventoryScan",
  components: {
    ScanModeDialog,
    InvalidScanDialog,
  },
  mixins: [mountsBarcodeHandlerWhenActive],
  props: {
    storeId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      scanList: {},
      scanMode: false,
      invalidScanDialog: false,
    };
  },
  computed: {
    scanListItems() {
      return values(this.scanList);
    },
  },
  watch: {
    scanMode: function (newScanMode) {
      // Clear bin scans when existing scan dialog
      if (!newScanMode) {
        this.scanList = {};
      }
    },
  },
  methods: {
    handleBarcode({ detail: { data: inventoryItemId } }) {
      // Guard: If disruptive dialogs are present, return early
      if ([this.invalidScanDialog].some(Boolean)) {
        return;
      }

      this.$apollo
        .query({
          loadingKey: "inventoryItemLoading",
          query: InventoryQuery,
          variables: {
            storeId: this.storeId,
            first: 1,
            last: null,
            after: null,
            before: null,
            sortBy: "id",
            descending: true,
            filter: JSON.stringify({ drsSki: inventoryItemId }),
          },
          update(data) {
            return data.inventoryItem;
          },
        })
        .then((response) => {
          if (
            response.data &&
            response.data.inventoryItems &&
            response.data.inventoryItems.edges &&
            response.data.inventoryItems.edges.length === 1
          ) {
            const inventoryItem = response.data.inventoryItems.edges[0].node;

            if (!this.scanList[inventoryItem.id]) {
              Vue.set(this.scanList, inventoryItem.id, inventoryItem);

              // If we just added the first item to scan list - show the item edit dialog.
              // If we added the second, third, etc.. - show the scan list
              if (keys(this.scanList).length === 1) {
                this.$router.push({
                  name: "inventory_show",
                  params: {
                    inventoryItemId: inventoryItem.id,
                  },
                  preserveQuery: true,
                });
              } else {
                this.scanMode = true;

                // If we are on inventory show page - hide the dialog
                if (this.$route.name === "inventory_show") {
                  this.$router.replace({
                    name: "inventory_home",
                    preserveQuery: true,
                  });
                }
              }
            }
          } else {
            this.invalidScanDialog = true;
          }
        });
    },
  },
};
</script>

<template>
  <div>
    <ScanModeDialog v-model="scanMode" :items="scanListItems" />
    <InvalidScanDialog v-model="invalidScanDialog" />
  </div>
</template>
