<script>
import InventoryListItem from "@/views/InventoryListView/InventoryListItem.vue";

import { hasSnackbarAccess } from "@/mixins/ui";
import { BULK_UPDATE_ITEMS } from "./BulkUpdate.gql";
import { map } from "lodash";

export default {
  name: "ScanModeDialog",

  components: {
    InventoryListItem,
  },
  mixins: [hasSnackbarAccess],
  inheritAttrs: false,

  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },

  data: () => ({
    error: false,
    setLocationDialog: false,
    setNoteDialog: false,
    renderKey: 0,
    newLocation: null,
    loading: false,
  }),

  computed: {
    itemWord() {
      return this.items.length === 1 ? "item" : "items";
    },
    titleText() {
      return `${this.items.length} ${this.itemWord} scanned`;
    },
  },

  methods: {
    selectInventoryItem(inventoryItem) {
      if (inventoryItem && inventoryItem.id) {
        this.$router.push({
          name: "inventory_show",
          params: { inventoryItemId: inventoryItem.id },
          preserveQuery: true,
        });
      }
    },
    back() {
      this.$emit("input", false);
    },
    removeItem(item) {
      var index = this.items.indexOf(item);
      if (index !== -1) this.items.splice(index, 1);
    },
    async bulkUpdateLocation() {
      this.loading = true;

      try {
        const {
          data: {
            bulkUpdateInventoryItems: { errors },
          },
        } = await this.$apollo.mutate({
          mutation: BULK_UPDATE_ITEMS,
          variables: {
            drsSkis: map(this.items, (item) => item.drsSki),
            storeId: this.$route.params.storeId,
            location: this.newLocation,
          },
        });

        if (errors.length) {
          throw errors.join(". ");
        }
        this.showSnackbar({
          text: `Items updated successfully.`,
        });

        this.$emit("input", false);
      } catch (error) {
        this.showSnackbar({
          text: `An error occurred while updating these items. ${error}`,
        });
      }
      this.loading = false;
    },
  },
};
</script>

<template>
  <v-dialog
    v-bind="$attrs"
    transition="dialog-bottom-transition"
    fullscreen
    scrollable
    v-on="$listeners"
  >
    <v-card>
      <v-toolbar color="ui lighten-4" flat>
        <v-btn icon @click="back">
          <v-icon v-text="`$vuetify.icons.back`" />
        </v-btn>
        <v-toolbar-title v-text="titleText" />
      </v-toolbar>
      <v-card-text class="scan-list-content">
        <v-row v-if="items.length > 0">
          <v-col cols="12">
            <InventoryListItem
              v-for="item in items"
              :key="item.id"
              :item="item"
              @show="selectInventoryItem"
            />

            <!-- <v-list :key="renderKey" two-line class="px-12">
            <v-chip
              v-for="item in items"
              :key="item.id"
              class="ma-2"
              close
              close-icon="$close"
              :loading="loading"
              @click:close="removeItem(item)"
              >{{ item.drsSki }}</v-chip
            >
          </v-list> -->
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="newLocation"
              label="New location"
              class="px-12"
            />
          </v-col>
          <v-col cols="3">
            <v-btn
              block
              large
              color="primary"
              :disabled="!newLocation"
              :loading="loading"
              @click="bulkUpdateLocation()"
              >Update</v-btn
            >
          </v-col>
        </v-row>
        <template v-else>
          <div class="pa-3 ui--text">No items selected.</div>
        </template>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<style scoped>
.scan-list-content {
  height: 100%;
}
</style>
